import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~5],
		"/blog": [~6],
		"/blog/[slug]": [7],
		"/change-password": [~8],
		"/companies": [~9],
		"/companies/new": [~12],
		"/companies/[slug]": [~10,[2]],
		"/companies/[slug]/update": [~11,[2]],
		"/faq": [13],
		"/jobs": [~14],
		"/jobs/guidelines": [17],
		"/jobs/new": [~18],
		"/jobs/[slug]": [~15,[3]],
		"/jobs/[slug]/update": [~16,[3]],
		"/legal": [~19,[4]],
		"/legal/acceptable-use-policy": [20,[4]],
		"/legal/cookie-policy": [21,[4]],
		"/legal/privacy-policy": [22,[4]],
		"/legal/terms-of-service": [23,[4]],
		"/login": [~24],
		"/logout": [~25],
		"/my-companies": [~26],
		"/my-jobs": [~27],
		"/payment": [~28],
		"/pricing": [~29],
		"/profile": [~30],
		"/register": [~31],
		"/reset-password": [~32]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';