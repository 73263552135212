import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';

Sentry.init(
	PUBLIC_SENTRY_DSN
		? {
				dsn: PUBLIC_SENTRY_DSN,
				tracesSampleRate: 1.0,
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0
			}
		: {}
);

export const handleError = Sentry.handleErrorWithSentry();
